

























































type AverageMetricData = {
  value: string
  classes: string
  icon: 'arrow-up' | 'arrow-down' | null
}

import MetricCard from '@/components/CommunityDashboard/components/MetricCard.vue'
import { DashboardExtended } from '@/includes/types/DashboardExtended/types'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    MetricCard
  }
})
export default class DashboardAverageStat extends Vue {
  @Prop() statistics!: DashboardExtended

  get isAvgStayHoursLarge() {
    return this.statistics.community.actual.average_stay_hours > 48
  }

  get averageStayHoursCurrentValue() {
    const average_stay_hours = this.statistics.community.actual.average_stay_hours

    if (this.isAvgStayHoursLarge) {
      return (average_stay_hours / 24).toFixed(0)
    } else {
      return average_stay_hours.toFixed(0)
    }
  }

  get avgStayHourMetricTitle() {
    if (this.isAvgStayHoursLarge) {
      return this.$t('dashboard_average_stay_hours_metric_by_day').toString()
    } else {
      return this.$t('dashboard_average_stay_hours_metric_by_hour').toString()
    }
  }

  get averageChat(): AverageMetricData {
    const { actual, prev } = this.statistics.community

    const actualHours = actual.average_stay_hours
    const prevHours = prev.average_stay_hours

    return this.mapToAverageStat(actualHours, prevHours, true)
  }

  get usersLeaveDay(): AverageMetricData {
    const { actual, prev } = this.statistics.community

    const actualLeave = actual.leave_before_1day.length
    const prevLeave = prev.leave_before_1day.length

    return this.mapToAverageStat(actualLeave, prevLeave)
  }

  get usersLeaveWeek(): AverageMetricData {
    const { actual, prev } = this.statistics.community

    const actualLeave = actual.leave_1day_to_7day.length
    const prevLeave = prev.leave_1day_to_7day.length

    return this.mapToAverageStat(actualLeave, prevLeave)
  }

  calcPercent(a: number, b: number): string {
    const calc = (first: number, second: number) => `${ (((first - second) / second) * 100).toFixed(1) }%`

    if (a > b) {
      return calc(a, b)
    } else if (a < b) {
      return calc(b, a)
    } else {
      return a.toString()
    }
  }

  iconClassByValuesDiff(current: number, prev: number, invert:boolean): string {
    if (current > prev) return invert ? 'text-success' : 'text-danger'
    else if (current < prev) return invert ? 'text-danger' : 'text-success'
    else return ''
  }

  iconTypeByValuesDiff(current: number, prev: number): AverageMetricData['icon'] {
    if (current > prev) return 'arrow-up'
    else if (current < prev) return 'arrow-down'
    else return null
  }

  mapToAverageStat(actual: number, prev: number, invert: boolean = false): AverageMetricData {
    return {
      value: this.calcPercent(actual, prev),
      classes: this.iconClassByValuesDiff(actual, prev, invert),
      icon: this.iconTypeByValuesDiff(actual, prev),
    }
  }

}
