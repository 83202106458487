

































































import { errorNotification } from '@/includes/NotificationService'
import { ActiveTopUser } from '@/includes/types/DashboardExtended/types'

import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'
import { sum } from 'lodash'

type ActiveTopUserWithChart = Omit<ActiveTopUser, 'items' | "total_message_count"> & {
  series: ApexChartSeries,
  monthTotal: number
}

@Component({
  components: {
    VueApexCharts,
    UserMainInfo,
    EmptyData
  }
})
export default class DashboardTopActiveUsers extends Vue {
  @Prop() activeUsers!: Array<ActiveTopUser>

  @Prop() from!:string

  @Prop() to!:string

  @Prop({ type: Boolean, required: false, default: true }) hasProfileButton!: boolean

  users: Array<ActiveTopUserWithChart> = []

  loading = false

  get usersLength() {
    return this.users.length
  }

  get activeUsersListClasses() {
    return [
      this.usersLength >= 5 ? 'lg:grid-rows-5' : `lg:grid-rows-${ this.usersLength }`,
      `grid-rows-${ this.users.length }`
    ]
  }

  get chartOptions(): ApexOptions {
    return {
      chart: {
        sparkline: {
          enabled: true,
        },
        animations: {
          enabled: false
        },
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: false,
            }
          }
        },
      },
      colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-warning), 1)' ],
      xaxis: {
        labels: {
          formatter(value: string, timestamp?: number, opts?: any): string | string[] {
            return moment(value).format('DD MMM YYYY')
          },
          show: false,
        },
        categories: fromToTicks(this.from, this.to).timeTicks,
      },
      yaxis: {
        show: false
      },
      legend: {
        show: false
      },
      grid: {
        show: false
      },
    }
  }

  gotoUser(userId: number) {
    this.$router.push({
      name: "user_info",
      params: {
        userId: userId.toString()
      }
    })
  }

  created() {
    this.loading = true

    const users = this.activeUsers
      .sort((a, b) => b.total_message_count - a.total_message_count)
      .slice(0, 10)
      .map(async (u) => {
        const { items, total_message_count, ...neededFields } = u
        const { calcSeries } = seriesByMetricInList(this.$i18n, items.map(i => ({ ...i, time: i.date })), [ 'message_count' ], fromToTicks(this.from, this.to))

        const series = await calcSeries()

        const monthTotal = sum(series[0].data)

        return {
          ...neededFields,
          monthTotal,
          series: await calcSeries(),
        }
      });

    Promise.all(users)
      .then((res) => {
        this.users = res
      })
      .catch(errorNotification)
      .finally(() => {
        this.loading = false
      })
  }
}
