import { ApexChartSeries, ApexChartSeriesItem } from 'piramis-base-components/src/shared/types/ApexChartSeries.types';
import { ChatMetricsOptional } from "@/includes/types/DashboardExtended/types";
import i18n from "@/i18n/i18n";

import { FromToTicksReturnType } from 'piramis-base-components/src/shared/utils/fromToTicks'

import { snakeCase } from "lodash";

export default function combinedRateSeries(fields:ChatMetricsOptional, fromTo: FromToTicksReturnType) {
  const { timeTicks, dataTicks } = fromTo

  const series: ApexChartSeries = []

  Object.entries(fields).forEach(([ key, rawField ]) => {
    let seriesItem: ApexChartSeriesItem = {
      name: i18n.t(`dashboard_statistics_${ snakeCase(key) }`).toString(),
      data: [ ...dataTicks ]
    }

    rawField.forEach(dataItem => {
      if (dataItem) {
        const dateIndex = timeTicks.indexOf(dataItem.time)
        seriesItem.data[dateIndex] = dataItem.value
      }
    })

    series.push(seriesItem)
  })

  return series
}
