


























type ErrorInfo = {
  type: DashboardExtendedErrors
  title: string
  helpMessageTrKey: string | null
}

import { DashboardExtendedErrors } from '@/includes/types/DashboardExtended/enums'

import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    HelpMessage
  }
})
export default class DashboardErrors extends Vue {
  @Prop() errors!: Array<DashboardExtendedErrors>

  AllErrors: Array<ErrorInfo> = [
    {
      type: DashboardExtendedErrors.log_channel,
      title: this.getTitleTranslationKey(DashboardExtendedErrors.log_channel),
      helpMessageTrKey: this.getHelpMessageTranslationKey(DashboardExtendedErrors.log_channel),
    },
    {
      type: DashboardExtendedErrors.no_webhook,
      title: this.getTitleTranslationKey(DashboardExtendedErrors.no_webhook),
      helpMessageTrKey: this.getHelpMessageTranslationKey(DashboardExtendedErrors.no_webhook),
    },
    {
      type: DashboardExtendedErrors.not_admin,
      title: this.getTitleTranslationKey(DashboardExtendedErrors.not_admin),
      helpMessageTrKey: this.getHelpMessageTranslationKey(DashboardExtendedErrors.not_admin),
    },
    {
      type: DashboardExtendedErrors.not_in_group,
      title: this.getTitleTranslationKey(DashboardExtendedErrors.not_in_group),
      helpMessageTrKey: this.getHelpMessageTranslationKey(DashboardExtendedErrors.not_in_group),
    },
    {
      type: DashboardExtendedErrors.not_supergroup,
      title: this.getTitleTranslationKey(DashboardExtendedErrors.not_supergroup),
      helpMessageTrKey: this.getHelpMessageTranslationKey(DashboardExtendedErrors.not_supergroup),
    },
  ]

  get errorsList() {
    return this.AllErrors.filter(e => this.errors.indexOf(e.type) !== -1)
  }

  getTitleTranslationKey(errorType: DashboardExtendedErrors): string {
    const trKey = `${ errorType }_error_title`

    return this.$te(trKey) ? this.$t(trKey).toString() : errorType.toString()
  }

  getHelpMessageTranslationKey(errorType: DashboardExtendedErrors): string | null {
    const trKey = `${ errorType }_help_message`

    return this.$te(trKey) ? this.$t(trKey).toString() : null
  }
}
