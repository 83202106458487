





















import { ChatMetrics } from '@/includes/types/DashboardExtended/types'
import ModerationStatNew from './MetricModerationCharts/ModerationStatNew.vue'
import BlockingStatNew from './MetricModerationCharts/BlockingStatNew.vue'
import CommandsStatNew from './MetricModerationCharts/CommandsStatNew.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    CommandsStatNew,
    BlockingStatNew,
    ModerationStatNew
  }
})
export default class MetricModeration extends Vue {
  @Prop() metrics!:ChatMetrics
}
