















import { ChatMetrics, ChatMetricsOptional } from '../../../../includes/types/DashboardExtended/types'
import TagsRow from '@/components/TagRow/TagsRow.vue'
import { sumCombinedRatesValues } from '@/components/CommunityDashboard/logic/sumCombinedRatesValues'
import { TagRowItem } from '@/components/TagRow/types'
import combinedRateSeries from '@/components/CommunityDashboard/logic/combinedRateSeries'

import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ApexOptions } from 'apexcharts'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

@Component({
  components: {
    TagsRow,
    VueApexCharts
  }
})
export default class ModerationStatNew extends Vue {
  @Prop() metrics!:ChatMetrics

  get chartOptions(): ApexOptions {
    return {
      chart: {
        type: 'bar',
        height: 350,
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      grid:{
        padding: {
          left: 0,
        }
      },
      colors: [  'rgba(var(--a-primary), 1)', 'rgba(var(--a-success), 1)', 'rgba(var(--a-danger), 1)', 'rgba(var(--a-warning), 1)'  ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: this.timeTicks().timeTicks.map(t => moment(t).format('D MMM')),
      },
    }
  }

  get calculateTotals():Array<TagRowItem> {
    const manualUserModerationTotal = sumCombinedRatesValues(this.metrics.manual_user_moderation)
    const manualAdminModerationTotal = sumCombinedRatesValues(this.metrics.manual_admin_moderation)
    const triggerPunishTotal = sumCombinedRatesValues(this.metrics.trigger_punish || [])
    const triggerUnpunishTotal = sumCombinedRatesValues(this.metrics.trigger_unpunish || [])

    return [
      {
        title: 'dashboard_statistics_manual_user_moderation_total',
        value: manualUserModerationTotal,
        color: 'rgba(var(--a-primary), 1)',
        icon: 'monitor',
      },
      {
        title: 'dashboard_statistics_manual_admin_moderation_total',
        value: manualAdminModerationTotal,
        color: 'rgba(var(--a-success), 1)',
        icon: 'safety',
      },
      {
        title: 'dashboard_statistics_trigger_punish_total',
        value: triggerPunishTotal,
        color: 'rgba(var(--a-danger), 1)',
        icon: 'lock',
      },
      {
        title: 'dashboard_statistics_trigger_unpunish_total',
        value: triggerUnpunishTotal,
        color: 'rgba(var(--a-warning), 1)',
        icon: 'unlock',
      },
    ]
  }

  get chartSeries() {
    const fields: Pick<ChatMetricsOptional, "manual_user_moderation" | "manual_admin_moderation" | "trigger_punish" | "trigger_unpunish"> = {
      manual_user_moderation: [ ...this.metrics?.manual_user_moderation ?? [] ],
      manual_admin_moderation: [ ...this.metrics?.manual_admin_moderation ?? [] ],
      trigger_punish: [ ...this.metrics?.trigger_punish ?? [] ],
      trigger_unpunish: [ ...this.metrics?.trigger_unpunish ?? [] ],
    }

    return combinedRateSeries(fields, this.timeTicks())
  }

  timeTicks() {
    return fromToTicks(this.metrics.period_start, this.metrics.period_end)
  }
}
