
































































const enum ChartColors {
  Core = "rgb(0, 174, 104)",
  NewActive = "rgb(0, 109, 184)",
  LostActive = "rgb(241, 102, 103)",
  Intersect = "rgb(254, 203, 100)",
  UnionNewActive = "rgba(0, 109, 184, 0.4)",
  UnionLostActive = "rgba(241, 102, 103, 0.4)",
  UnionIntersect = "rgba(254, 203, 100, 0.4)"
}

enum CoreUsersGroup {
  Core = 'Core',
  NewActive = 'NewActive',
  LostActive = 'LostActive',
  Intersect = 'Intersect',
  UnionNewActive = 'UnionNewActive',
  UnionLostActive = 'UnionLostActive',
  UnionIntersect = 'UnionIntersect',
}

type LegendInfo = {
  type: CoreUsersGroup
  label: string
  helpMessage: string
  color: ChartColors
  usersCount: number
  messageCount: number
  button: {
    title: string
    icon: string
    onClick: () => void
  }
}

import CoreUsersStatList from './CoreUsersStat/CoreUsersStatList.vue'
import { ExtendedCommunity } from '@/includes/types/DashboardExtended/types'
import { errorNotification } from '@/includes/NotificationService'

import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'

import { kebabCase, sum } from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    CoreUsersStatList,
    HelpMessage
  },
  data() {
    return {
      sum,
      kebabCase
    }
  }
})
export default class CoreChartUserInfosLegend extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) showUnion!: boolean

  @Prop({ type: Object, required: true }) statistics!: ExtendedCommunity

  coreUsersModalActive = false

  get legend(): Array<LegendInfo> {
    const newActiveLen = Object.keys(this.statistics.new_active_users).length
    const lostActiveLen = Object.keys(this.statistics.lost_active_users).length
    const intersectLen = Object.keys(this.statistics.intersected_active_users).length

    let indifferentNewLen = 0
    let indifferentLostActiveLen = 0
    let indifferentIntersectLen = 0

    if (this.showUnion) {
      indifferentNewLen = Object.keys(this.statistics.indifferent_new_active_users!).length
      indifferentLostActiveLen = Object.keys(this.statistics.indifferent_lost_active_users!).length
      indifferentIntersectLen = Object.keys(this.statistics.indifferent_intersected_active_users!).length
    }
    return [
      ...this.statistics.actual.core.length ? [ {
        type: CoreUsersGroup.Core,
        label: this.$t('dashboard_core_group_title').toString(),
        helpMessage: this.$t('dashboard_core_group_help_message').toString(),
        color: ChartColors.Core,
        usersCount: this.statistics.actual.core.length,
        messageCount: sum(this.statistics.actual.core.map(u => u.message_count)),
        button: {
          icon: "ordered-list",
          title: this.$t('dashboard_legend_group_core_button_title').toString(),
          onClick: () => {
            this.coreUsersModalActive = true
          }
        }
      } ] : [ ],
      ...intersectLen ? [ {
        type: CoreUsersGroup.Intersect,
        label: this.$t('dashboard_core_intersect_title').toString(),
        helpMessage: this.$t('dashboard_core_intersect_help_message').toString(),
        color: ChartColors.Intersect,
        usersCount: intersectLen,
        messageCount: sum(Object.values(this.statistics.intersected_active_users)),
        button: {
          title: this.$t('dashboard_legend_group_users_analyse_button_title').toString(),
          icon: "bar-chart",
          onClick: () => {
            const usersId = Object.keys(this.statistics.intersected_active_users)

            this.checkUsersAnalysis(usersId)
          }
        }
      } ] : [ ],
      ...newActiveLen ? [ {
        type: CoreUsersGroup.NewActive,
        label: this.$t('dashboard_core_new_active_title').toString(),
        helpMessage: this.$t('dashboard_core_new_active_help_message').toString(),
        color: ChartColors.NewActive,
        usersCount: newActiveLen,
        messageCount: sum(Object.values(this.statistics.new_active_users)),
        button: {
          icon: "bar-chart",
          title: this.$t('dashboard_legend_group_users_analyse_button_title').toString(),
          onClick: () => {
            const usersId = Object.keys(this.statistics.new_active_users)

            this.checkUsersAnalysis(usersId)
          }
        }
      } ] : [ ],
      ...lostActiveLen ? [ {
        type: CoreUsersGroup.LostActive,
        label: this.$t('dashboard_core_lost_active_title').toString(),
        helpMessage: this.$t('dashboard_core_lost_active_help_message').toString(),
        color: ChartColors.LostActive,
        usersCount: lostActiveLen,
        messageCount: sum(Object.values(this.statistics.lost_active_users)),
        button: {
          title: this.$t('dashboard_legend_group_users_analyse_button_title').toString(),
          icon: "bar-chart",
          onClick: () => {
            const usersId = Object.keys(this.statistics.lost_active_users)

            this.checkUsersAnalysis(usersId)
          }
        }
      } ] : [ ],

      ...this.showUnion
        ? [
          ...indifferentIntersectLen ? [ {
            type: CoreUsersGroup.UnionIntersect,
            label: this.$t('dashboard_core_union_intersect_title').toString(),
            helpMessage: this.$t('dashboard_core_union_intersect_help_message').toString(),
            color: ChartColors.UnionIntersect,
            usersCount: indifferentIntersectLen,
            messageCount: sum(Object.values(this.statistics.indifferent_intersected_active_users!)),
            button: {
              title: this.$t('dashboard_legend_group_users_analyse_button_title').toString(),
              icon: "bar-chart",
              onClick: () => {
                const usersId = Object.keys(this.statistics.indifferent_intersected_active_users!)

                this.checkUsersAnalysis(usersId)
              }
            }
          } ] : [],
          ...indifferentNewLen ? [ {
            type: CoreUsersGroup.UnionNewActive,
            label: this.$t('dashboard_core_union_new_active_title').toString(),
            helpMessage: this.$t('dashboard_core_union_new_active_help_message').toString(),
            color: ChartColors.UnionNewActive,
            usersCount: indifferentNewLen,
            messageCount: sum(Object.values(this.statistics.indifferent_new_active_users!)),
            button: {
              title: this.$t('dashboard_legend_group_users_analyse_button_title').toString(),
              icon: "bar-chart",
              onClick: () => {
                const usersId = Object.keys(this.statistics.indifferent_new_active_users!)

                this.checkUsersAnalysis(usersId)
              }
            }
          } ] : [ ],
          ...indifferentLostActiveLen ? [
            {
              type: CoreUsersGroup.UnionLostActive,
              label: this.$t('dashboard_core_union_lost_active_title').toString(),
              helpMessage: this.$t('dashboard_core_union_lost_active_help_message').toString(),
              color: ChartColors.UnionLostActive,
              usersCount: indifferentLostActiveLen,
              messageCount: sum(Object.values(this.statistics.indifferent_lost_active_users!)),
              button: {
                title: this.$t('dashboard_legend_group_users_analyse_button_title').toString(),
                icon: "bar-chart",
                onClick: () => {
                  const usersId = Object.keys(this.statistics.indifferent_lost_active_users!)

                  this.checkUsersAnalysis(usersId)
                }
              }
            } ] : [],

        ] : []
    ]
  }

  checkUsersAnalysis(usersId: Array<string>) {
    if (usersId.length) {
      this.$store.commit('set_users', usersId)

      this.$router.push({ name: 'statistics_users_analysis' })
    } else {
      errorNotification('_empty users')
    }
  }
}
