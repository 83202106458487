















































































type FrontendAdminUsersTimelineRecord = Omit<AdminUsersTimelineRecord, 'users'> & {
  users: number
}

import { AdminUsersTimelineRecord, ExtendedCommunity } from "@/includes/types/DashboardExtended/types";
import CoreUsersStatUser from "./components/CoreUsersStatUser.vue";
import { CHATKEEPER_BOT_LOGIN } from "@/includes/constants";

import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import { ApexChartLocales, ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";
import Accordion from "piramis-base-components/src/components/Accordion/Accordion.vue";
import HelpMessage from "piramis-base-components/src/components/HelpMessage/HelpMessage.vue";
import HighlightAnchor from "piramis-base-components/src/components/HighlightAnchor.vue";

import { Vue, Component, Prop } from 'vue-property-decorator';
import { ApexOptions } from "apexcharts";
import VueApexCharts from "vue-apexcharts";

@Component({
  components: {
    CoreUsersStatUser,
    VueApexCharts,
    Accordion,
    HelpMessage,
    HighlightAnchor
  }
})
export default class AdminUsersStatistics extends Vue {
  @Prop() adminUsersTimeline!: ExtendedCommunity['admin_users_timeline']

  @Prop() adminUsersStat!: ExtendedCommunity['admin_users_stat']

  @Prop() from!: string

  @Prop() to!: string

  @Prop({ type: Boolean, required: false, default: true }) hasProfileButton!: boolean

  chartData: ApexChartSeries | null = null

  get adminUsersStatSorted() {
    return this.adminUsersStat
      .filter(a => a.login !== CHATKEEPER_BOT_LOGIN)
      .slice()
      .sort((a, b) => {
        if (a.message_count > b.message_count) return -1
        if (a.message_count < b.message_count) return 1

        if (a.reply_count > b.reply_count) return -1
        if (a.reply_count < b.reply_count) return 1

        return 0
      })
  }

  get adminUsersStatSortedOtherLength() {
    return this.adminUsersStatSorted.slice(3).length
  }

  get chartOptions(): ApexOptions {
    return {
      dataLabels: {
        enabled: false,
      },
      chart: {
        animations: {
          enabled: false
        },
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexChartLocales
      },
      colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-warning), 1)',  'rgba(var(--a-success), 1)' ],
      xaxis: {
        type: 'datetime',
        labels: {
          show: true,
        },
        categories: this.fromTo.timeTicks,
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      grid: {
        show: true
      },
    }
  }

  get fromTo() {
    return fromToTicks(this.from, this.to)
  }

  async prepareFrontendTimeline(timeline: ExtendedCommunity['admin_users_timeline']) {
    return timeline.map<FrontendAdminUsersTimelineRecord>(value => ({
      ...value,
      users: value.users.length
    }))
  }

  async created() {
    this.prepareFrontendTimeline(this.adminUsersTimeline)
      .then((frontendTimeline) => {
        const { calcSeries } = seriesByMetricInList(this.$i18n, frontendTimeline, [ 'message_count', 'reply_count', "users" ], this.fromTo)

        calcSeries()
          .then((series) => {
            this.chartData = series
          })
      })

  }
}
