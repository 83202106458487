










































































import { CommunityDashboardUser } from '@/includes/types/DashboardExtended/types'
import CoreUsersStatUser from '@/components/CommunityDashboard/components/CoreUsersStatUser.vue'

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import DrawerWidthMixin from 'piramis-base-components/src/logic/helpers/DrawerWidthMixin'

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    CoreUsersStatUser,
    EmptyData
  },
})
export default class DynamicUsersList extends Mixins<DrawerWidthMixin>(DrawerWidthMixin) {
  @Prop({ type: Array, default: () => [] }) list!: Array<CommunityDashboardUser>

  @Prop() leavedUsers!:Record<number, number>

  LIMIT = 2

  usersListMoreModal = false

  selectedMoreList:Array<CommunityDashboardUser> = []

  get firstLimitItems() {
    return this.list.slice(0, this.LIMIT)
  }

  get otherLimitItems() {
    return this.list.slice(this.LIMIT)
  }

  handleMoreUsersClick() {
    this.selectedMoreList = this.otherLimitItems;
    this.usersListMoreModal = true
  }

  created() {
    this.initializeSidebarWidth(1024)
  }
}
