export enum DashboardExtendedErrors {
  no_webhook  = 'no_webhook',
  not_in_group  = 'not_in_group',
  not_admin  = 'not_admin',
  not_supergroup  = 'not_supergroup',
  log_channel  = 'log_channel',
}

export enum CoreState {
  stable = 'stable',
  medium = 'medium',
  low = 'low',
}
