






















import DashboardApi from '@/includes/Api/Dashboard.api'
import CoreUsersStatUser from '@/components/CommunityDashboard/components/CoreUsersStatUser.vue'
import { CommunityDashboardUser, ExtendedCommunity } from '@/includes/types/DashboardExtended/types'

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { sum, sumBy } from 'lodash'

@Component({
  components: {
    CoreUsersStatUser,
    UserMainInfo
  }
})
export default class CoreUsersChartUser extends Vue {
  @Prop() statistics!: ExtendedCommunity

  @Prop() userId!:string | null

  dataLoading = false

  userInfo:CommunityDashboardUser | null = null

  get groupInfoMessage() {
    const { actual, new_active_users, lost_active_users, intersected_active_users } = this.statistics

    const message = (group:string, groupCount:number, msgCount:number, groupMsgCount:number) => this.$t('dashboard_core_user_group_info', [ group, groupCount, msgCount, groupMsgCount ])

    if (this.userInfo) {
      const userId = this.userInfo.user_id

      if (actual.core.some(u => u.user_id === userId)) {
        const user = actual.core.find(u => u.user_id === userId)

        return message(this.$t('dashboard_core_group_title').toString(), actual.core.length, user!.message_count, sumBy(actual.core, u => u.message_count))
      }

      if (new_active_users[userId]) {
        return message(this.$t('dashboard_core_new_active_title').toString(), Object.keys(new_active_users).length, new_active_users[userId], sum(Object.values(new_active_users)))
      }

      if (lost_active_users[userId]) {
        return message(this.$t('dashboard_core_lost_active_title').toString(), Object.keys(lost_active_users).length, lost_active_users[userId], sum(Object.values(lost_active_users)))
      }

      if (intersected_active_users[userId]) {
        return message(this.$t('dashboard_core_intersect_title').toString(), Object.keys(intersected_active_users).length, intersected_active_users[userId], sum(Object.values(intersected_active_users)))
      }
    }

    return ''
  }

  @Watch('userId', { immediate: true })
  onUserIdChange(id:string | null) {
    if (id) {
      this.getUserInfo(Number.parseInt(id))
    }
  }

  get cachedUsers() {
    return [
      ...this.statistics.actual.core,
      ...this.statistics.actual.applicants,
      ...this.statistics.prev.core,
      ...this.statistics.prev.applicants
    ]
  }

  async getUserInfo(id:number) {
    this.dataLoading = true

    const chatId = Number.parseInt(this.$route.params[EntityTypes.CHAT_ID])

    try {
      const cachedUser = this.cachedUsers.find(u => u.user_id === id)

      if (cachedUser) {
        this.userInfo = cachedUser
      } else {
        const res = await DashboardApi.getUsersCommunityStat(chatId, [ id ]);

        this.userInfo = res.data.users[0]
      }
    } finally {
      this.dataLoading = false
    }
  }
}
