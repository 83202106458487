import { deleteRecurringInvoice, getRecurringInvoicesReq } from "@/includes/logic/RecurringInvoices/logic";

import { InvoiceInfoWithTargets } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/Shared/InvoiceInfo.types";

import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class RecurringInvoicesMixin extends Vue {
  licenseInfoLoading = false

  invoice: InvoiceInfoWithTargets | null = null

  deleteRecurringInvoice() {
    if (this.invoice) {
      deleteRecurringInvoice(this.invoice, this)
          .then(res => {
            if (res) {
              this.invoice = null
            }
          })
    }
  }

  created() {
    this.licenseInfoLoading = true

    getRecurringInvoicesReq()
        .then(res => {
          if (res && this.$store.getters.isChatSet) {
            const target = this.$store.getters.chatId

            this.invoice = this.findInvoiceWithTarget(target, res)
          }
        })
        .finally(() => {
          this.licenseInfoLoading = false
        })
  }

  findInvoiceWithTarget(target: number, invoices: Array<InvoiceInfoWithTargets>) {
    const resInvoices: Array<InvoiceInfoWithTargets> = []

    for (const invoice of invoices) {
      if (invoice.positions && invoice.positions.length) {
        if (invoice.positions.some(p => p.item_id && target === p.item_id)) {
          resInvoices.push(invoice)
        }
      }
    }

    if (resInvoices.length) {
      return resInvoices.sort((a, b) => b.invoice_number - a.invoice_number)[0]
    }

    return null
  }
}
