








import TagsRow from '@/components/TagRow/TagsRow.vue'
import { ChatMetrics } from '@/includes/types/DashboardExtended/types'

import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'

@Component({
  components: {
    TagsRow,
    VueApexCharts
  }
})
export default class TriggersFiringStat extends Vue {
  @Prop() metrics!: ChatMetrics

  get triggersStatSeries() {
    const { mapTimeData } = this.timeTicks()

    this.metrics.triggers_stat.forEach(record => {
      const formattedTime = moment(record.time).format('YYYY-MM-DD')

      mapTimeData[formattedTime] += record.count
    })

    return [ {
      data: Object.values(mapTimeData),
      name: this.$t('dashboard_statistics_triggers_executions')
    } ]
  }

  timeTicks() {
    return fromToTicks(this.metrics.period_start, this.metrics.period_end)
  }
}
