


















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { TagRowItem } from '@/components/TagRow/types'

@Component
export default class TagsRow extends Vue {
  @Prop({ type: Array, default: () => [] }) tags!:Array<TagRowItem>
}
