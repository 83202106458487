












































































































import DashboardErrors from '@/components/CommunityDashboard/DashboardErrors.vue'
import DashboardModules from '@/components/CommunityDashboard/DashboardModules.vue'
import DashboardYearStat from '@/components/CommunityDashboard/DashboardYearStat.vue'
import DashboardAverageStat from '@/components/CommunityDashboard/DashboardAverageStat.vue'
import DashboardCoreStat from '@/components/CommunityDashboard/DashboardCoreStat.vue'
import DashboardMetrics from '@/components/CommunityDashboard/DashboardMetrics.vue'
import DashboardLids from '@/components/CommunityDashboard/DashboardLids.vue'
import DashboardApi from '@/includes/Api/Dashboard.api'
import { DashboardExtended, SummaryStatRecord } from '@/includes/types/DashboardExtended/types'
import { errorNotification } from '@/includes/NotificationService'
import DashboardTopActiveUsers from '@/components/CommunityDashboard/DashboardTopActiveUsers.vue'
import DashboardChatInfo from '@/components/CommunityDashboard/DashboardChatInfo.vue'
import AdminUsersStatistics from "@/components/CommunityDashboard/AdminUsersStatistics.vue";
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import DashboardReactionsStat from "@/components/CommunityDashboard/DashboardReactionsStat.vue";

import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import Vue from 'vue'
import { Component, Ref, Watch } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  components: {
    DashboardReactionsStat,
    AdminUsersStatistics,
    NotAvailableOptionsOverlay,
    DashboardChatInfo,
    DashboardTopActiveUsers,
    DashboardErrors,
    DashboardModules,
    DashboardYearStat,
    DashboardCoreStat,
    DashboardAverageStat,
    DashboardLids,
    DashboardMetrics,
    PageTitle
  },
  data() {
    return {
      moment
    }
  }
})
export default class CommunityDashboard extends Vue {

  chatStat: DashboardExtended | null = null

  summaryStat: Array<SummaryStatRecord> | null = null

  dashboardErrors: Array<DashboardErrors> | null = null

  statisticsTriggered = false

  isError = false

  @Ref('allStat') allStatBlock!: HTMLDivElement

  resetState() {
    this.dashboardErrors = null
    this.chatStat = null
    this.summaryStat  = null

    this.statisticsTriggered = false
  }

  @Watch('allStatBlock')
  onChange(el: HTMLDivElement) {
    if (el) {
      const io = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            if (!this.statisticsTriggered) {
              this.statisticsTriggered = true

              this.loadStatistics()
            }
          }
        });
      });

      io.observe(el)
    }
  }

  @Watch(`$route.params.CHAT_ID`)
  onChadIdChange() {
    this.resetState()

    this.loadSummaryStatistics()
  }

  loadStatistics() {
    DashboardApi.getDashBoardCommunityStatistics(Number.parseInt(this.$route.params[EntityTypes.CHAT_ID]))
      .then(({ data }) => {
        this.chatStat = data
      })
      .catch(errorNotification)
  }

  loadDashboardStatus() {
    const chatId = this.$store.getters.chatId

    if (chatId) {
      if (localStorage) {
        const CHAT_DASHBOARD_STATUS_LS_KEY = 'chat_dashboard_status'
        const lsDashboardStatus = localStorage.getItem(CHAT_DASHBOARD_STATUS_LS_KEY)!

        if (!lsDashboardStatus) {
          localStorage.setItem(CHAT_DASHBOARD_STATUS_LS_KEY, JSON.stringify({}))
        }

        const twoHours = 3600000 * 2

        const dashboardStatusInfo = JSON.parse(lsDashboardStatus) ?? {}
        const chatStat = dashboardStatusInfo[chatId]

        if (chatStat && Date.now() - chatStat.time < twoHours) {
          this.dashboardErrors = chatStat.errors
        } else {
          DashboardApi.getDashBoardStatus(chatId)
            .then(({ data }) => {
              this.dashboardErrors = data.errors

              dashboardStatusInfo[chatId] = {
                errors: data.errors,
                time: Date.now()
              }

              localStorage.setItem(CHAT_DASHBOARD_STATUS_LS_KEY, JSON.stringify(dashboardStatusInfo))
            })
            .catch(errorNotification)
        }
      } else {
        DashboardApi.getDashBoardStatus(chatId)
          .then(({ data }) => {
            this.dashboardErrors = data.errors
          })
          .catch(errorNotification)
      }
    }
  }

  loadSummaryStatistics() {
    DashboardApi.getDashBoardSummaryStatistics(this.$store.getters.chatId)
      .then(({ data }) => {
        this.summaryStat = data.summary_statistics

        this.loadDashboardStatus()
      })
      .catch(error => {
        errorNotification(error)

        this.isError = true
      })
  }

  mounted(): void {
    this.$baseTemplate.saveButton.hide()

    if (!this.$store.getters.botNotAdmin) {
      this.loadSummaryStatistics()
    }
  }

  destroyed() {
    this.$baseTemplate.saveButton.show()
  }
}
