






























import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component({})
export default class EntityCardWithStatistics extends Vue {
  @Prop() cardTitle!:string

  @Emit('onSettingClick')
  onSettingClick() {}

  get filterCardStyles(): Partial<CSSStyleDeclaration> {
    return {
      paddingBottom: "0px",
      flex: "1",
      display: "flex",
      flexDirection: "column"
    }
  }
}
