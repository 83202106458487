











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class GotoProfileButton extends Vue {
  @Prop() userId!:number | string

  get id() {
    if (typeof this.userId === 'number') {
      return this.userId.toString()
    }

    return this.userId
  }

  gotoUserProfile() {
    this.$router.push({
      name: 'user_info',
      params: {
        userId: this.id
      }
    })
  }

}
