















































































enum UnionEnum {
  UNION_NEW_ACTIVE_USERS = 'UNI_NEW_ACTIVE',
  UNION_LOST_ACTIVE_USERS = 'UNI_LOST_ACTIVE',
  UNION_INTERSECT_USERS = 'UNI_INTERSECT',
}

import { DashboardExtended } from '@/includes/types/DashboardExtended/types'
import CoreUsersStatList from '@/components/CommunityDashboard/CoreUsersStat/CoreUsersStatList.vue'
import DynamicUsers from '@/components/CommunityDashboard/DynamicUsers/DynamicUsers.vue'
import MetricCard from '@/components/CommunityDashboard/components/MetricCard.vue'
import CoreUsersChartUser from '@/components/CommunityDashboard/CoreUsersStat/CoreUsersChartUser.vue'
import { errorNotification } from '@/includes/NotificationService'
import CoreChartUserInfosLegend from '@/components/CommunityDashboard/CoreChartUserInfosLegend.vue'

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import PostApi from 'piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { selectAll } from 'd3'
import axios from 'axios'
import { isEmpty, sum } from 'lodash'

@Component({
  components: {
    CoreChartUserInfosLegend,
    CoreUsersChartUser,
    MetricCard,
    DynamicUsers,
    CoreUsersStatList,
    UserMainInfo,
    EmptyData,
    ConfigField
  },
  data() {
    return {
      sum,
    }
  }
})
export default class DashboardCoreStat extends Vue {
  @Prop() statistics!: DashboardExtended

  svg = null

  coreUsersModalActive = false

  userInfoModalActive = false

  currentUserId: string | null = null

  dataForChartExists = true

  chartLoading = false

  get activeUsers() {
    return Object.keys(this.statistics.community.actual.active_users).length
  }

  get activePercent() {
    return (this.activeUsers / this.statistics.members_count) * 100
  }

  get activeUsersTitle() {
    if (this.activePercent >= 100) {
      return this.$t('dashboard_users_active_count').toString()
    } else {
      return this.$t('dashboard_users_active_percent').toString()
    }
  }

  get activeUsersValue(): string {
    if (this.activeUsers > 0) {
      if (this.activePercent >= 100) {
        return this.activeUsers.toString()
      } else {
        return this.activePercent.toFixed(1)
      }

    }

    return '-'
  }

  get showUnionLegendItems() {
    return !!this.statistics.community?.indifferent_new_active_users
      || !!this.statistics.community?.indifferent_lost_active_users
      || !!this.statistics.community?.indifferent_intersected_active_users
  }

  mounted(): void {
    const { community, community_chart_token } = this.statistics

    if (community.actual.core.length
      || !isEmpty(community.new_active_users)
      || !isEmpty(community.lost_active_users)
      || !isEmpty(community.intersected_active_users)
    ) {
      this.chartLoading = false

      axios.get(`${ PostApi.getApiUrl() }tg/getdashboardcommunitychart?token=${ community_chart_token }`, {
        withCredentials: true
      })
        // axios.post("http://localhost:13380/createcommunitychart", { community })
        .then(({ data }) => {
          this.svg = data
        })
        .then(() => {
          this.chartLoading = false

          this.$nextTick(() => {
            selectAll('.cell')
              .on('click', ((event: MouseEvent) => {
                const attrValue = event.target && (event.target as SVGSVGElement).getAttribute('u')

                if (attrValue && Object.values(UnionEnum).indexOf(attrValue as UnionEnum) === -1) {
                  this.currentUserId = attrValue
                  this.userInfoModalActive = true
                }

              }))
          })
        })
        .catch((e) => {
          errorNotification(e)
          this.dataForChartExists = false
        })
        .finally(() => {
          this.chartLoading = false
        })

    } else {
      this.dataForChartExists = false
    }
  }
}
