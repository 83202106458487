



















































import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    HelpMessage
  }
})
export default class MetricCard extends Vue {
  @Prop() title!: string

  @Prop() helpMessage!: string

  @Prop() value!: string | number

  @Prop({ type: Boolean, default: false }) stretch!:boolean

  get metricCardStyles(): Partial<CSSStyleDeclaration> {
    return {
      ...this.stylesIfSlot
    }
  }

  get stylesIfSlot(): Partial<CSSStyleDeclaration> {
    if (this.$slots.chart) {
      return {
        paddingBottom: '0px'
      }
    }

    return {
      height: '100%',
    }
  }
}
