















































































import { CommunityDashboardUser } from '@/includes/types/DashboardExtended/types'
import GotoProfileButton from '@/components/CommunityDashboard/components/GotoProfileButton.vue'
import isMobile from "@/assets/utils/isMobile";
import { ANONYMOUS_USER_LOGIN } from '@/includes/constants';

import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue';

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'

@Component({
  components: {
    GotoProfileButton,
    VueApexCharts,
    UserMainInfo,
    EmptyData,
    Icon,
    HelpMessage
  },
  data() {
    return {
      isMobile,
      ANONYMOUS_USER_LOGIN
    }
  }
})
export default class CoreUsersStatUser extends Vue {
  @Prop() user!: CommunityDashboardUser

  @Prop({ type: Boolean, default: false, required: false }) withChart !:boolean

  @Prop({ type: Boolean, default: false, required: false }) smallChart !:boolean

  @Prop() leavedUsers!:Record<number, number>

  @Prop({ type: Boolean, required: false, default: true }) hasProfileButton!: boolean

  from = moment().add(-2, 'month').format('YYYY-MM-DD')

  to = moment().format('YYYY-MM-DD')

  chartData: ApexChartSeries = []

  get cardBodyStyles(): Partial<CSSStyleDeclaration> {
    return {
      padding: '12px 0 0 0',
      minWidth: '300px',
      width: "100%",
    }
  }

  get cardHeadStyles(): Partial<CSSStyleDeclaration> {
    return {
      flex: '1',
    }
  }

  get chartDates() {
    return fromToTicks(this.from, this.to)
  }

  get leavedValue() {
    const value = this.leavedUsers && this.leavedUsers[this.user.user_id]

    if (value) {
      if (value > 24) {
        const fv = Math.floor(value / 24)

        return this.$tc('user_leaved_group_value_days', fv, [ fv ]).toString()
      } else {
        return this.$tc('user_leaved_group_value_hours', value, [ value ]).toString()
      }
    }
  }

  get chartOptions(): ApexOptions {
    return {
      chart: {
        animations: {
          enabled: false
        },
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: false,
            }
          }
        },
      },
      colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-warning), 1)' ],
      xaxis: {
        labels: {
          formatter(value: string, timestamp?: number, opts?: any): string | string[] {
            return moment(value).format('DD MMM YYYY')
          },
          show: false,
        },
        categories: this.chartDates.timeTicks,
      },
      yaxis: {
        show: false
      },
      legend: {
        show: false
      },
      grid: {
        show: false
      },
    }
  }

  created() {
    if (this.user.timeline) {
      const seriesByMetric = seriesByMetricInList(this.$i18n, this.user.timeline, [ 'message_count', 'reply_count' ], fromToTicks(this.from, this.to))

      const { calcSeries } = seriesByMetric

      calcSeries()
        .then(res => this.chartData = res)
    }
  }
}
