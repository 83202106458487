


































import GotoProfileButton from '@/components/CommunityDashboard/components/GotoProfileButton.vue'
import { RepUserFrontend } from './types'

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    UserMainInfo,
    GotoProfileButton
  }
})
export default class TopReputationUserCard extends Vue {
  @Prop() title!:string

  @Prop() user!: RepUserFrontend

}
