

























import { ChatMetrics } from '@/includes/types/DashboardExtended/types'
import UserProfileApi from '@/includes/Api/UserProfile.api'
import { UserProfile } from '@/includes/types/UserProfile/types'
import TopReputationUserCard from './TopReputationUserCard.vue'
import { RepUserFrontend } from './types'
import { errorNotification } from '@/includes/NotificationService'

import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import Vue from 'vue'
import { max } from 'lodash'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    TopReputationUserCard,
    UserMainInfo,
    EmptyData
  }
})
export default class ReputationUsersTop extends Vue {
  @Prop() metrics!:ChatMetrics

  topReputationActor: RepUserFrontend | null = null

  topReputationTarget: RepUserFrontend | null = null

  error = false

  findMaxSumInRecords<T extends Record<any, number>>(model: T) {
    const maxValue = max(Object.values(model))

    return Object.entries(model)
      .find(([ _, v ]) => v === maxValue)
  }

  reputationUserFabric(userInfo: UserProfile, sum: number): RepUserFrontend {
    const { avatar, user_login, user_name, user_id } = userInfo

    return  {
      sum,
      avatar,
      user_login,
      user_id,
      user_name
    }
  }

  created() {
    const userIds: Array<number> = []

    const chatId = Number.parseInt(this.$route.params[EntityTypes.CHAT_ID])

    const actorTopUser = this.findMaxSumInRecords(this.metrics.reputation_actor_sum)
    const targetTopUser = this.findMaxSumInRecords(this.metrics.reputation_target_sum)

    if (actorTopUser) {
      userIds.push(Number.parseInt(actorTopUser[0]))
    }

    if (targetTopUser) {
      userIds.push(Number.parseInt(targetTopUser[0]))
    }

    UserProfileApi.getUsersProfileInfo(chatId, userIds)
      .then(({ data }) => {
        const users = data.users;

        const topTarget = users.find(u => u.user_id === Number.parseInt(actorTopUser![0]))
        const topActor = users.find(u => u.user_id === Number.parseInt(targetTopUser![0]))

        if (topTarget) {
          this.topReputationTarget = this.reputationUserFabric(topTarget, actorTopUser![1])
        }

        if (topActor) {
          this.topReputationActor = this.reputationUserFabric(topActor, targetTopUser![1])
        }
      })
      .catch((e) => {
        errorNotification(e)
        this.error = true
      })
  }
}
