



























































enum CurrentChart {
  Users,
  Messages,
  UserMessages
}

import { SummaryStatRecord } from '@/includes/types/DashboardExtended/types'

import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'

import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import { Component, Prop } from 'vue-property-decorator'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'

@Component({
  components: {
    VueApexCharts,
    EmptyData,
    ConfigField,
    HighlightAnchor
  },
  data() {
    return {
      CurrentChart
    }
  }
})
export default class DashboardYearStatMore extends Vue {
  @Prop() statistics!: Array<SummaryStatRecord>

  chartData: ApexChartSeries = []

  current: CurrentChart = CurrentChart.Users

  get currentSeries() {
    if (this.current === CurrentChart.Users) {
      return this.usersChart
    } else if (this.current === CurrentChart.Messages) {
      return this.messagesChart
    } else {
      return this.usersAverageChart
    }
  }

  get chartColor() {
    if (this.current === CurrentChart.Users) {
      return [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-primary), 1)' ]
    } else if (this.current === CurrentChart.Messages) {
      return [ 'rgba(var(--a-warning), 1)', 'rgba(var(--a-warning), 1)' ]
    } else {
      return [ 'rgba(var(--a-success), 1)', 'rgba(var(--a-success), 1)' ]
    }
  }

  get chartOptions(): ApexOptions {
    return {
      dataLabels: {
        enabled: false
      },
      chart: {
        animations: {
          enabled: false
        },
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
      },
      fill: {
        type: 'pattern',
        opacity: 1,
        pattern: {
          style: [ undefined, 'slantedLines' ] as Array<string>,
        },
      },
      colors: this.chartColor,
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: false,
            }
          },
        },
      },
      yaxis: {
        decimalsInFloat: this.current === CurrentChart.UserMessages ? 1 : 0
      },
      xaxis: {
        categories: this.timeTicks().timeTicks,
        labels: {
          formatter(value: string, timestamp?: number, opts?: any): string | string[] {
            return moment(value).format('MMM YYYY')
          }
        },
      },
      legend: {
        show: false
      },
    }
  }

  timeTicks() {
    const startOfMonth = moment().startOf('month')

    const from = moment(startOfMonth.clone().add('-1', 'year'), 'YYYY-MM-DD').format('YYYY-MM-DD')
    const momentTo = moment(startOfMonth, 'YYYY-MM-DD').format('YYYY-MM-DD')

    return fromToTicks(from, momentTo, { unit: 'month', amount: 1 })
  }

  usersChart: ApexChartSeries = [ ]
  messagesChart: ApexChartSeries = [ ]
  usersAverageChart: ApexChartSeries = [ ]

  get calculateUsersAverage() {
    return this.statistics.map(r => {
      return {
        ...r,
        user_message_avg: r.message_count === 0 ? 0 : (r.message_count / r.users_count),
        approx_user_message_avg: (r?.approx_users_count && r?.approx_message_count)
          ? Math.abs((r.message_count + r.approx_message_count) / (r.users_count + r.approx_users_count) - (r.message_count / r.users_count))
          : 0
      }
    })
  }

  created() {
    const usersCountSeries = seriesByMetricInList(this.$i18n, this.statistics, [ 'users_count', 'approx_users_count' ], this.timeTicks())
    const messageCountSeries = seriesByMetricInList(this.$i18n, this.statistics, [ 'message_count', 'approx_message_count' ], this.timeTicks())
    const usersAverageSeries = seriesByMetricInList(this.$i18n, this.calculateUsersAverage, [ 'user_message_avg', 'approx_user_message_avg' ], this.timeTicks())

    Promise.all([ usersCountSeries.calcSeries(), messageCountSeries.calcSeries(), usersAverageSeries.calcSeries() ])
      .then((res) => {
        this.usersChart.push(...res[0])
        this.messagesChart.push(...res[1])
        this.usersAverageChart.push(...res[2])
      })
  }
}
