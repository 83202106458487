
















import { ChatMetrics, ChatMetricsOptional } from '../../../../includes/types/DashboardExtended/types'
import combinedRateSeries from '../../logic/combinedRateSeries'
import { TagRowItem } from '../../../TagRow/types'
import { sumCombinedRatesValues } from '../../logic/sumCombinedRatesValues'
import TagsRow from '@/components/TagRow/TagsRow.vue'

import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'

@Component({
  components: {
    TagsRow,
    VueApexCharts
  }
})
export default class CommandsStatNew extends Vue {
  @Prop() metrics!:ChatMetrics

  get chartOptions(): ApexOptions {
    return {
      chart: {
        type: 'bar',
        height: 350,
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-danger), 1)', ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: this.timeTicks().timeTicks.map(t => moment(t).format('D MMM')),
      },
    }
  }

  get chartSeries() {
    const fields: Pick<ChatMetricsOptional, "commands"> = {
      commands: [ ...this.metrics?.commands ?? [] ],
    }

    return combinedRateSeries(fields, this.timeTicks())
  }

  get calculateTotals():Array<TagRowItem> {
    const commandsTotal = sumCombinedRatesValues(this.metrics.commands)

    return [
      {
        title: 'dashboard_statistics_commands_total',
        value: commandsTotal,
        color: 'rgba(var(--a-primary), 1)',
        icon: 'alert',
      },
    ]
  }

  timeTicks() {
    return fromToTicks(this.metrics.period_start, this.metrics.period_end)
  }
}
