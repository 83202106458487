






































































import { CommunityDashboardUser, ExtendedCommunity } from '@/includes/types/DashboardExtended/types'
import UserCardIcon from '@/components/CommunityDashboard/DynamicUsers/UserCardIcon.vue'
import DynamicUsersList from '@/components/CommunityDashboard/DynamicUsers/DynamicUsersList.vue'

import WindowResizeMixin from 'piramis-base-components/src/logic/helpers/WindowResizeMixin'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    DynamicUsersList,
    UserCardIcon,
    HelpMessage
  }
})
export default class DynamicUsers extends Mixins<WindowResizeMixin>(WindowResizeMixin) {

  @Prop() community!: ExtendedCommunity

  currentList = null

  created() {
    this.lostActiveUsers
  }

  get lostActiveUsers() {
    const { actual, prev } = this.community

    return actual.applicants
      .filter(u => prev.core.some(lu => lu.user_id === u.user_id))
      .sort(this.sortCondition)
  }

  get leavedFromCore() {
    const { actual, prev } = this.community

    return prev.core
      .filter(pu => !actual.applicants.some(au => au.user_id === pu.user_id))
      .filter(pu => !actual.core.some(acu => acu.user_id === pu.user_id))
      .sort(this.sortCondition)
  }

  get applicantsUsers() {
    const { actual, prev } = this.community

    return actual.applicants
      .filter(au => !prev.core.some(pu => pu.user_id === au.user_id))
      .sort(this.sortCondition)
  }

  sortCondition(a:CommunityDashboardUser, b:CommunityDashboardUser) {
    if (a.message_count > b.message_count) return -1
    if (a.message_count < b.message_count) return 1

    if (a.reply_count > b.reply_count) return -1
    if (a.reply_count < b.reply_count) return 1

    return 0
  }

}
