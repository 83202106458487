




























































































































import { ChatMetrics } from '@/includes/types/DashboardExtended/types'
import VuTriggers from '@/includes/logic/VuTriggers'
import TriggersFiringStat from '@/components/CommunityDashboard/MetricComponents/MetricTriggerCharts/TriggersFiringStat.vue'
import MetricCard from '../components/MetricCard.vue'
import EntityCardWithStatistics from '@/components/CommunityDashboard/MetricComponents/EntityCardWithStatistics.vue'
import { triggersStatistics } from '@/components/CommunityDashboard/logic/metrics/triggersStatistics'

import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import { ApexOptions } from 'apexcharts'

@Component({
  components: {
    EntityCardWithStatistics,
    EmptyData,
    Accordion,
    TriggersFiringStat,
    VueApexCharts,
    MetricCard
  }
})
export default class MetricTriggers extends Vue {
  @Prop() metrics!: ChatMetrics

  isOpen = false

  triggersStat = triggersStatistics(this.metrics)

  get activeTriggers() {
    return this.$store.state.chatState.chat.triggers
  }

  get chartOptions(): ApexOptions {
    return {
      grid: {
        padding: {
          top: 10
        }
      },
      chart: {
        type: 'bar',
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true
        }
      },
      tooltip: {
        y: {
          formatter(val: number, opts?: any): string {
            return val.toFixed(0)
          }
        }
      },
      colors: [ 'rgba(var(--a-primary), 1)' ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: this.triggersStat.triggersTimeTicks().timeTicks.map(t => moment(t).format('D MMM')),
      },
    }
  }

  get triggersFiringChartOptions(): ApexOptions {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        sparkline:{
          enabled: true
        }
      },
      colors: [ 'rgba(var(--a-primary), 1)' ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: this.triggersStat.triggersFiringTimeTicks().timeTicks.map(t => moment(t).format('D MMM')),
      },
    }
  }

  onSettingTriggerClick(id:string) {
    this.$router.push({ name: "chat_trigger", params: { actionType: "edit" }, query: { triggerId: id } })
  }

  created() {
    if (!this.$store.state.triggersState.frontendTriggers) {
      VuTriggers.updateTriggers()
    }
  }
}
