var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-migrate-card"},[_c('a-modal',{staticClass:"dashboard-migrate-card__modal",attrs:{"ok-text":_vm.$t('modules_migrate_modal_warn_ok_button'),"cancel-text":_vm.$t('modules_migrate_modal_warn_cancel_button'),"ok-button-props":{
      props: {
        icon: 'thunderbolt'
      }
    }},on:{"ok":_vm.doMigrate},model:{value:(_vm.isMigrateModuleModalOpen),callback:function ($$v) {_vm.isMigrateModuleModalOpen=$$v},expression:"isMigrateModuleModalOpen"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('modules_modal_migrate_warn_message_title'))}})]),_c('a-card',{staticClass:"dashboard-migrate-card__card bg-blue-200 flex flex-col relative h-full",attrs:{"body-style":{height: '100%'},"hoverable":_vm.needMigrate},on:{"click":function($event){_vm.needMigrate ? _vm.isMigrateModuleModalOpen = true : false}}},[(_vm.needMigrate)?_c('div',{staticClass:"absolute z-40 font-extrabold text-2xl md:text-3xl text-blue-700 text-center select-none left-0 right-0",staticStyle:{"top":"50%","transform":"translateY(-50%)"}},[_c('a-icon',{staticClass:"align-middle",style:({
          fontSize: _vm.$screen.md ? '6rem' : '3rem',
          color: _vm.YELLOW
        }),attrs:{"type":"thunderbolt"}}),_vm._v(" "+_vm._s(_vm.$t('modules_migrate_preview_card_title'))+" ")],1):_vm._e(),_c('div',{staticClass:"h-full flex flex-1 items-center z-10 select-none",style:({ filter: _vm.needMigrate ? 'blur(10px)' : '' })},[_c('div',[_c('h3',{staticClass:"text-base md:text-lg"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_active_modules_card_title'))+" ")]),_c('span',{staticClass:"text-base md:text-5xl"},[_vm._v(" "+_vm._s(_vm.moduleManager.getActiveModules().length)+" из "+_vm._s(_vm.moduleManager.totalModules())+" ")])]),_c('a-icon',{staticClass:"ml-auto text-primary",style:({ fontSize: _vm.$screen.md ? '7rem' : '3rem' }),attrs:{"type":"thunderbolt"}})],1),(!_vm.isConfigDeprecated)?_c('div',{staticClass:"text-white hover:opacity-75",attrs:{"slot":"actions"},on:{"click":function($event){return _vm.$router.push({ name: 'ActiveModules'})}},slot:"actions"},[_vm._v(" "+_vm._s(_vm.$t('customize'))+" ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }