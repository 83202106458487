













import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class UserCardIcon extends Vue {
  @Prop() userState!: "leave" | "applicant" | "lost_active"

  get iconClass() {
    if (this.userState === "applicant") return 'text-success'
    if (this.userState === "leave") return 'text-danger'
    if (this.userState === "lost_active") return 'text-warning'

    return ''
  }

  get iconType() {
    if (this.userState === "applicant") return 'usergroup-add'
    if (this.userState === "leave") return 'usergroup-delete'
    if (this.userState === "lost_active") return 'frown'

    return ''

  }

  get titleByUserState() {
    if (this.userState === "applicant") return this.$t('FrontendCoreUserState.applicant')
    if (this.userState === "leave") return this.$t('FrontendCoreUserState.leave')
    if (this.userState === "lost_active") return this.$t('FrontendCoreUserState.lost_active')

    return ''
  }
}
