import Api from "@/includes/logic/Api";
import { CommunityDashboardUser, DashboardExtended, SummaryStatRecord } from "@/includes/types/DashboardExtended/types";
import DashboardErrors from "@/components/CommunityDashboard/DashboardErrors.vue";

export default class DashboardApi {

  static async getDashBoardStatus(chat_id: number): Promise<{ data: { errors: Array<DashboardErrors> } }> {
    return Api.sendRequest('tg', 'getdashboardstatus', { chat_id })
  }

  static async getDashBoardSummaryStatistics(chat_id: number): Promise<{ data: {summary_statistics: Array<SummaryStatRecord>} }> {
    return Api.sendRequest('tg', 'getdashboardsummarystatistics', { chat_id })
  }

  static async getDashBoardCommunityStatistics(chat_id: number): Promise<{ data: DashboardExtended }> {
    return Api.sendRequest('tg', 'getdashboardcommunitystatistics', { chat_id })
  }

  static async getUsersCommunityStat(chat_id: number, user_ids: Array<number>): Promise<{ data: { users: Array<CommunityDashboardUser> } }> {
    return Api.sendRequest('tg', 'getuserscommunitystat', { chat_id, user_ids })
  }
}
