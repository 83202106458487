
































import UniqueUsersReputationChart from './MetricReputationCharts/UniqueUsersReputationChart.vue'
import { ChatMetrics } from '../../../includes/types/DashboardExtended/types'
import ReputationSumChart from './MetricReputationCharts/ReputationSumChart.vue'
import ReputationUsersTop from './MetricReputationCharts/ReputationUsersTop.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    ReputationUsersTop,
    ReputationSumChart,
    UniqueUsersReputationChart,
  }
})
export default class MetricReputation extends Vue {
  @Prop() metrics!: ChatMetrics

  @Prop() isReputationEnabled!: boolean
}
