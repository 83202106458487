



























import { CommunityCore } from '@/includes/types/DashboardExtended/types'
import CoreUsersStatUser from '@/components/CommunityDashboard/components/CoreUsersStatUser.vue'

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    CoreUsersStatUser,
    UserMainInfo,
    EmptyData
  }
})
export default class CoreUsersStatList extends Vue {
  @Prop() communityCore!: CommunityCore

  get usersWithStat() {
    return this.communityCore
      .slice()
      .sort((a, b) => {
        if (a.message_count > b.message_count) return -1
        if (a.message_count < b.message_count) return 1

        if (a.reply_count > b.reply_count) return -1
        if (a.reply_count < b.reply_count) return 1

        return 0
      })
  }
}
